/* Modals */

.ReactModal__Body--open {
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
}

.ReactModal__Content--after-open {
  position: absolute;
  height: 100%;
  overflow: auto;
  outline: none;
}

.ReactModal__Overlay--after-open {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 9999;
  -webkit-overflow-scrolling: touch;
}

// Simple animation
.ReactModalPortal > div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  transition: opacity 400ms ease-in-out;

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}
