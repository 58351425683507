@import 'assets/styles/_variables.scss';

.bottom-page-bar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90;
  pointer-events: none;
}

.bottom-page-bar-body {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.bottom-page-bar {
  position: fixed;
  pointer-events: auto;
  width: 100%;
  height: auto !important;
  min-height: 11 * $smidge;
  z-index: 2;
  border-top: 1px solid $grey;

  &.open {
    @include animation(slideUpOpen 2s);

    bottom: 0;
  }

  &.closed {
    @include animation(slideUpClose 2s);

    bottom: -500px;
  }
}
