@import 'assets/styles/_variables.scss';

.not-found-page {
  text-align: center;

  .not-found-section {
    padding-top: 5 * $smidge;
    padding-bottom: 3 * $smidge;

    @media #{$small} {
      padding-top: 10 * $smidge;
      padding-bottom: 15 * $smidge;
    }
  }

  .not-found-top {
    h3 {
      margin-bottom: 4 * $smidge;
    }

    p {
      text-align: left;
      font-size: 16px;
    }
  }

  .not-found-image {
    display: block;
    margin: auto;
    margin-top: 4 * $smidge;
    width: 100%;
    max-width: 250px;

    @media #{$small} {
      margin-top: 2 * $smidge;
    }
  }

  .info-section {
    padding-top: 5 * $smidge;
    padding-bottom: 5 * $smidge;

    @media #{$small} {
      &.info-first {
        padding-top: 11 * $smidge;
      }
    }

    &.info-last {
      padding-bottom: 14 * $smidge;

      a {
        color: $secondary;
      }
    }

    h3 {
      color: $navy;
      text-align: center;
      margin-top: 0;
      margin-bottom: 0;
    }

    p {
      margin-top: 2 * $smidge;
      margin-bottom: 3 * $smidge;
    }
  }

  h1 {
    font-size: 48px;
    line-height: 56px;
    text-align: center;

    @media #{$small} {
      text-align: left;
    }
  }

  h3 {
    text-align: center;

    @media #{$small} {
      text-align: left;
    }
  }
}
