$smidge: 8px;

// Style guide main colors
$white: #fff;
$beige: #f7f7f7;
$grey: #dae0e4;
$light-grey: #f3f6f9;
$space: #7e8187;
$slate: #58595b;
$navy: #263547;
$fuego: #ff5345;
$blue: #177fff;
$denim: #134a8d;
$sea-green: #f8fcff;
$background-blue: #f6fbff;
$light-blue: #f3f6f9;
$dark-navy: #003f71;
$red: #a32e25;
$denim: #134a8d;
$black: #000;

// Color gradients
$blue-gradient: linear-gradient(to right, #33b6ff, $blue);

// Style guide accent colors
$buildzoom-blue: #4abde9;
$dark-blue: #004dff;
$green: #00b295;
$yellow: #ffb900;

// Alert backgrounds
$success-background: #e4fff2;
$info-background: #d9eeff;
$danger-background: #ffe2e3;

// Bootstrap colors
$primary: $blue;
$secondary: $blue;
$info: $secondary;
$warning: $yellow;
$success: $green;

// Pixel constants
$navbar-height: 80px;
$border-radius: 2px;

// SCREEN VARIABLES
$extra-small: "only screen and (max-width : 767px)";
$small: "only screen and (min-width : 768px)";
$medium: "only screen and (min-width : 992px)";
$desktop: "only screen and (min-width : 1200px)";

$max-extra-small: "only screen and (max-width : 767px)";
$max-small: "only screen and (max-width : 991px)";
$max-medium: "only screen and (max-width : 1199px)";
$max-desktop: "only screen and (min-width : 1200px)";

$page-medium: "only screen and (min-width : 1100px)";

// Browser specific variables
$ff-only: "url-prefix()";
$ie-edge: "(-ms-ime-align: auto)";
$ie-only: "all and (-ms-high-contrast: none), (-ms-high-contrast: active)";

// MIXINS
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin background-size($args...) {
  -webkit-background-size: $args;
  -moz-background-size: $args;
  -o-background-size: $args;
  background-size: $args;
}

@mixin scale($scale) {
  @include transform(scale($scale));
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-ms-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
