@import 'assets/styles/_variables.scss';

.loader-container {
  text-align: center;
  padding-top: 100px;

  &.translucent {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.5);

    .loader-three-dots {
      position: fixed;
      top: 50%;
      left: 50%;
      margin-top: -12px;
      margin-left: -12px;
    }
  }
}

.loader-three-dots,
.loader-three-dots:before,
.loader-three-dots:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation: load7 1.8s infinite ease-in-out;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}

.loader-three-dots {
  color: $blue;
  font-size: 10px;
  margin: 50px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader-three-dots:before,
.loader-three-dots:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader-three-dots:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader-three-dots:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@-webkit-keyframes fade {
  0%,
  100% {
    opacity: 0;
  }

  20%,
  80% {
    opacity: 1;
  }
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }

  20%,
  80% {
    opacity: 1;
  }
}

.loading-text {
  -webkit-animation: fade 3s infinite;
  animation: fade 3s infinite;
}
