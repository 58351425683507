/* Buttons */
.btn {
  font-weight: 500;
  border-radius: 50px;
  border-width: 1px;
  border-style: solid;
  font-size: 18px;
  padding: 12px 24px;

  background-color: $white;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;

  &:focus,
  &:active:focus,
  &.active:focus {
    outline: 0 none;
  }

  &:active,
  &.active {
    box-shadow: none;
  }

  .btn-lock {
    padding-bottom: 5px;
    margin-left: 5px;
  }

  .loader {
    width: 100px;
    height: 100%;
  }
}

.btn-link {
  color: $navy;
  border: 0;
  background-color: transparent;
  box-shadow: none !important;

  circle {
    fill: $navy;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:hover,
  &.active:hover {
    text-decoration: none;
  }
}

.btn-lg {
  min-width: 150px;
  padding: 15px 20px;
  height: 56px;
  font-size: 18px;
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(38, 53, 71, 0.5);

  @include transition(transform 0.3s, box-shadow 0.3s);

  &:hover {
    box-shadow: 0 3px 6px 0 rgba(38, 53, 71, 0.5);

    @include transform(translateY(-2px));
  }

  &:focus {
    box-shadow: 0 3px 6px 0 rgba(38, 53, 71, 0.5);
  }

  &:active {
    box-shadow: 0 1px 3px 0 rgba(38, 53, 71, 0.5);
  }
}

.btn-default {
  background-color: $white;
  border-color: $secondary;
  color: $secondary;

  circle {
    fill: $secondary;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:hover,
  &.active:hover {
    background-color: $secondary;
    border-color: $secondary;
    color: $white;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    opacity: 1;
  }
}

.btn-primary {
  background: $blue-gradient;
  border: none;
  color: $white;

  circle {
    fill: $white;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:hover,
  &[disabled]:hover,
  &.active:hover {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    opacity: 1;
  }
}

.btn-secondary {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;

  circle {
    fill: $white;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:hover,
  &.active:hover {
    background-color: $secondary;
    border-color: $secondary;
    color: $white;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    opacity: 1;
  }
}

.btn-danger {
  background-color: $primary;
  border-color: $primary;
  color: $white;

  circle {
    fill: $white;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:hover,
  &.active:hover {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    opacity: 1;
  }
}

.btn-transparent {
  background-color: transparent;
  border-color: $white;
  color: $white;

  circle {
    fill: $white;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:hover,
  &.active:hover {
    background-color: transparent;
    border-color: $white;
    color: $white;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: transparent;
    border-color: $white;
    color: $white;
    opacity: 1;
  }
}

.btn-grey {
  background-color: transparent;
  border-color: $white;
  color: $space;
  font-weight: 400;
  font-size: 16px;

  circle {
    fill: $white;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:hover,
  &.active:hover {
    background-color: transparent;
    border-color: $white;
    opacity: 75%;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: transparent;
    border-color: $white;
    color: $white;
    opacity: 1;
  }
}

.btn-info {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;

  circle {
    fill: $white;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:hover,
  &.active:hover {
    background-color: $secondary;
    border-color: $secondary;
    color: $white;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    opacity: 1;
  }
}

.btn-warning {
  background-color: $yellow;
  border-color: $yellow;
  color: $white;

  circle {
    fill: $white;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:hover,
  &.active:hover {
    background-color: $yellow;
    border-color: $yellow;
    color: $white;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    opacity: 1;
  }
}

.btn-space {
  background-color: $space;
  border-color: $space;
  color: $white;

  circle {
    fill: $white;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:hover,
  &.active:hover {
    background-color: $space;
    border-color: $space;
    color: $white;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    opacity: 1;
  }
}

.btn-blue {
  background: $blue-gradient;
  border: none;
  color: $white;

  circle {
    fill: $white;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:hover,
  &.active:hover {
    background-color: $blue;
    border: none;
    color: $white;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    opacity: 1;
  }
}

.btn-yellow {
  background-color: $yellow;
  border-color: $yellow;
  color: $black;

  circle {
    fill: $white;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:hover,
  &.active:hover {
    background-color: $yellow;
    border-color: $yellow;
    color: $black;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    opacity: 1;
  }
}

.btn-green {
  background-color: $green;
  border-color: $green;
  color: $white;

  circle {
    fill: $white;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:hover,
  &.active:hover {
    background-color: $green;
    border-color: $green;
    color: $white;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    opacity: 1;
  }
}

.btn-white-outlined {
  background-color: $white;
  border-color: $black;
  color: $black;

  circle {
    fill: $white;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:hover,
  &.active:hover {
    background-color: $white;
    border-color: $black;
    color: $black;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    opacity: 1;
  }
}

.btn-blue {
  background: $blue-gradient;
  border: none;
  color: $white;

  circle {
    fill: $white;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:hover,
  &.active:hover {
    background-color: $blue;
    border: none;
    color: $white;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    opacity: 1;
  }
}


@media #{$small} {
  .width-240-sm {
    width: 240px;
  }
}

@media #{$max-small} {
  .btn-block-max-sm {
    display: block;
    margin: auto;
    width: 100%;
  }
}

.btn-block-sm {
  display: block;
  width: 100%;

  @media #{$max-small} {
    width: 160px;
    margin: auto;
  }
}

.btn-blue-empty {
  background-color: $white;
  border-color: $grey;
  color: $blue;
  font-weight: 300;

  circle {
    fill: $blue;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:hover,
  &.active:hover {
    background-color: $blue;
    border-color: $blue;
    color: $white;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    opacity: 1;
  }
}

.btn-denim {
  background-color: $white;
  border-color: $denim;
  color: $denim;
  font-weight: 300;

  circle {
    fill: $blue;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $grey;
    color: $denim;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    opacity: 1;
  }
}

.btn-denim-filled {
  background-color: $denim;
  border-color: $denim;
  color: $white;
  font-weight: 300;

  circle {
    fill: $blue;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $denim;
    border-color: $denim;
    color: $white;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    opacity: 1;
  }
}

.btn-blue-hover {
  &:hover {
    background-color: $blue;
    border-color: $blue;
  }
}

.btn-navy-empty {
  background-color: $white;
  border-color: $navy;
  color: $navy;
}
