@import 'assets/styles/_variables.scss';

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
}

.popup-modal {
  width: 100%;

  .popup-container {
    height: 100%;
  }

  .popup {
    position: relative;
    width: 100%;
    min-height: 100%;
    padding: 24px;
    pointer-events: auto;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    @include animation('fadeIn 1s');

    @media #{$small} {
      margin: 5% auto;
      padding-bottom: 3 * $smidge;
      width: 610px;
      min-height: auto;
    }

    .close-button {
      background: none;
      outline: none;
      border: none;
      padding: 0;
      height: 24px;
      width: 24px;
      margin-bottom: (2 * $smidge);
    }

    .cta-button {
      margin: (2 * $smidge) auto;

      &:last-child {
        margin: (4 * $smidge) auto 0 auto;
      }
    }
  }

  ul .btn {
    width: 100%;
    margin-bottom: 15px;
    height: 6 * $smidge;
  }

  hr {
    margin: (4 * $smidge) auto;
  }

  &.exit-popup {
    h2 {
      line-height: 56px;
      margin-bottom: 0;
    }

    h4 {
      line-height: 40px;
      margin: 0 auto (4 * $smidge);
    }
  }
}
