/* Original footer CSS */
footer#footer {
  background-color: $white;
  color: $space;
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  padding-top: 5 * $smidge;
  border-top: solid 1px $grey;

  .container {
    @media #{$desktop} {
      width: 1080px;
    }
  }

  @media #{$small} {
    padding-top: 4 * $smidge;
    padding-bottom: $smidge;
  }

  ul {
    list-style: none;
    padding: 0;
    text-align: left;
    color: $space;

    li {
      padding-bottom: 4px;
      margin-bottom: 2 * $smidge;
    }
  }

  h6 {
    font-size: 14px;
    line-height: 32px;
    font-weight: 500;
    text-align: left;
    color: $navy;
    margin-top: 0;
  }

  a {
    color: $slate;
  }

  .help-text-box {
    .help-text {
      font-size: 14px;
      display: block;

      @media #{$small} {
        display: inline;
        margin-right: 32px;
      }
    }

    @media #{$small} {
      padding-top: 2 * $smidge;
    }
  }

  .fa-angle-down {
    color: $grey;
    font-size: 25px;
    margin-left: 8px;
    position: absolute;
    top: 3px;
  }

  .trust-logos {
    .trust-icons {
      margin: auto;
      margin-top: 2 * $smidge;
      width: 100%;
      max-width: 350px;
    }

    @media #{$medium} {
      max-width: 250px;
      float: right;
    }
  }

  .company-text {
    text-align: center;
    border-bottom: 1px solid $grey;
    padding-bottom: 3 * $smidge;

    @media #{$medium} {
      padding-bottom: $smidge;
      text-align: left;
    }

    .hearth-icon {
      display: block;
      margin: 0 auto $smidge;
      width: 24px;
      height: 24px;

      @media #{$small} {
        display: inline-block;
        margin: -4px 10px 0 0;
      }
    }
  }

  .footer-link-container {
    margin-bottom: 3 * $smidge;
  }

  .hearth-symbol-black {
    padding-bottom: 10px;

    @media #{$small} {
      padding-bottom: 2px;
    }
  }
}

/* Standalone disclosure module CSS */
.disclosure-footer {
  background-color: $beige;
  // extra space at the bottom for mobile offers page footer
  font-size: 10px;
  padding: (3 * $smidge) 0 60px;
  padding-top: 3 * $smidge;
  color: $space;

  .container {
    @media #{$desktop} {
      width: 1080px;
    }
  }

  p {
    font-size: 16px;
    line-height: 16px;
  }

  p small {
    font-size: 12px;
    font-weight: 300;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  &.dark {
    background-color: $slate;
    color: white;

    a {
      color: $beige;
      text-decoration: none;

      &:hover {
        color: $slate;
      }
    }
  }

  &.light {
    background-color: $beige;
    color: $slate;

    a {
      color: $navy;
      text-decoration: none;

      &:hover {
        color: $slate;
      }
    }
  }

  &.white-label-disclosure {
    border-top: solid 1px $grey;
    padding-top: 50px;
  }
}
