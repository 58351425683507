.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  white-space: normal;

  .tooltip-trigger {
    @media #{$small} {
      pointer-events: none;
    }
  }

  .tooltip-modal {
    cursor: initial;
    visibility: hidden;
    opacity: 0;
    text-align: left;
    background-color: white;
    position: absolute;
    z-index: 1;
    border: 1px solid $grey;
    box-shadow: 0 1px 3px 0 $grey;

    @include transition(opacity 0.2s ease);

    .close-button {
      position: absolute;
      right: 18px;
      top: 0;
      font-size: 4 * $smidge;
      color: $slate;
      cursor: pointer;

      &:hover {
        color: $space;
      }

      @media #{$small} {
        visibility: hidden;
      }
    }

    .overflow-container {
      overflow: auto;
      padding: (5 * $smidge) (3 * $smidge);

      @media #{$small} {
        padding: 25px 20px;
        width: 300px;
        max-height: 400px;
      }

      .normal-text {
        color: $slate;
        margin: 0;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
      }

      h5 {
        color: $slate;
        margin: 0;
      }
    }
  }

  .tooltip-top-left {
    bottom: calc(100% + 20px);
    right: calc(50% - 30px);
  }

  .tooltip-top-right {
    bottom: calc(100% + 20px);
    left: calc(50% - 30px);
  }

  .tooltip-bottom-left {
    top: calc(100% + 20px);
    right: calc(50% - 30px);
  }

  .tooltip-bottom-right {
    top: calc(100% + 20px);
    left: calc(50% - 30px);
  }

  .tooltip-modal::after,
  .tooltip-modal::before {
    content: "";
    position: absolute;
    border-color: transparent;
    border-style: solid;
    cursor: pointer;
  }

  .tooltip-modal::before {
    border-width: 16px;
  }

  .tooltip-modal::after {
    border-width: 14px;
  }

  @media #{$small} {
    .tooltip-top-left::before {
      top: 100%;
      right: 10px;
      border-top-color: $grey;
    }

    .tooltip-top-left::after {
      top: 100%;
      right: 12px;
      border-top-color: $white;
    }

    .tooltip-top-right::before {
      top: 100%;
      left: 10px;
      border-top-color: $grey;
    }

    .tooltip-top-right::after {
      top: 100%;
      left: 12px;
      border-top-color: $white;
    }

    .tooltip-bottom-left::before {
      bottom: 100%;
      right: 10px;
      border-bottom-color: $grey;
    }

    .tooltip-bottom-left::after {
      bottom: 100%;
      right: 12px;
      border-bottom-color: $white;
    }

    .tooltip-bottom-right::before {
      bottom: 100%;
      left: 10px;
      border-bottom-color: $grey;
    }

    .tooltip-bottom-right::after {
      bottom: 100%;
      left: 12px;
      border-bottom-color: $white;
    }
  }

  @media #{$extra-small} {
    .tooltip-mobile-false {
      opacity: 0;
      display: none;
    }

    .tooltip-mobile-true {
      position: fixed;
      top: $navbar-height;
      right: 0;
      left: 0;
      bottom: 0;
      border: 0;
      opacity: 1;
      visibility: visible;
      z-index: 98;
    }
  }

  @media #{$small} {
    &:hover .tooltip-modal {
      opacity: 1;
      visibility: visible;
    }
  }

  .double-row-mobile {
    @media #{$max-extra-small} {
      margin-top: 25px;
    }
  }
}
