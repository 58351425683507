@include keyframes(fadeIn) {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@include keyframes(slideUpOpen) {
  0% {
    @include transform(translateY(500px));
  }

  100% {
    @include transform(translateY(0));
  }
}

@include keyframes(slideUpClose) {
  0% {
    @include transform(translateY(0));

    bottom: 0;
  }

  100% {
    @include transform(translateY(500px));
  }
}
