.quill {
  .ql-editor {
    p {
      font-size: 18px;
      line-height: 27px;
      color: $navy;
    }

    img {
      max-height: 400px;
    }

    pre.ql-syntax {
      background-color: $beige;
      color: $navy;
      word-wrap: break-word;
      white-space: pre;
      overflow-y: hidden;
    }
  }
}
