.highlight-text {
  color: $blue !important;
}

.yellow-text {
  color: $yellow !important;
}

.blue-text {
  color: $blue !important;
}

.fuego-text {
  color: $fuego !important;
}

.navy-text {
  color: $navy !important;
}

.space-text {
  color: $space !important;
}

.slate-text {
  color: $slate !important;
}

.white-text {
  color: $white !important;
}

.grey-text {
  color: $grey !important;
}

.green-text {
  color: $green !important;
}

.red-text {
  color: $red !important;
}

.bold-text {
  font-weight: 900;
}

.grey-border {
  border: solid 1px $grey;
}

.grey-border-bottom {
  border-bottom: solid 1px $grey;
}

.mg-r-10 {
  margin-right: 10px;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.border-none {
  border: none !important;
}

.break-word {
  word-break: break-word;
}

.no-wrap {
  white-space: nowrap;
}

.text-light {
  font-weight: 300;
}

.text-medium {
  font-weight: 500;
}

.text-strong {
  font-weight: 900;
}

.font-size-14 {
  font-size: 14px;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.full-width {
  width: 100%;
}

.wrap-word {
  word-wrap: break-word;
}

@media #{$small} {
  .max-width-50-sm {
    max-width: 50%;
  }
}

.display-inline-block {
  display: inline-block;
}

.line-height-large {
  line-height: 2;
}

.line-height-small {
  line-height: 1;
}

.navy-gradient-background {
  background-image: linear-gradient(101deg, #4e657d, $navy);
}

.background-transparent {
  background-color: transparent !important;
}

.background-light-blue {
  background-color: $light-blue;
}

.grey-color {
  color: $grey;
}

.line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}
