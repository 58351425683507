// Use for wireframing/debugging
// body * { border: 1px solid red; }

body {
  background-color: $white;
  // temp change until full web color audit
  color: #0D2135;
  font-family: "Poppins";
  font-weight: 300;
  font-size: 18px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

body.modal-open {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

a {
  cursor: pointer;
}

small.terms,
li.terms {
  font-size: 11px;
  color: $slate;
  line-height: 12px;
  margin-bottom: $smidge;
}

h5.text-above {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $fuego;
  margin-bottom: 0;
}

.birthday-container {
  padding: 0 10px;

  .col-xs-4 {
    padding: 0 5px;
  }
}

/* Panels */
.panel {
  .panel-body {
    padding: 40px;
  }

  &.panel-default {
    border: none;
    border-radius: 0;
    padding-top: 40px;

    & > .panel-heading {
      background: none;
      border: none;
      border-radius: 0;
      padding: 3px 38px;
      color: $navy;
    }
  }
}

// Components
.info-section {
  padding-top: 40px;
  padding-bottom: 40px;
  background-size: cover;

  &.info-section-last {
    padding-bottom: 80px;
  }
}

@media #{$small} {
  .info-section {
    padding-top: 70px;
    padding-bottom: 70px;

    &.info-section-last {
      padding-bottom: 140px;
    }
  }
}

#intercom-container {
  display: none;

  @media #{$small} {
    display: inherit;
  }
}

.intercom-off #intercom-container {
  display: none;
}

.turbolinks-progress-bar {
  background-color: $navy;
}

.whitelabel-top-level {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
