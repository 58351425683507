@import 'assets/styles/_variables.scss';

.base-contract {
  overflow-y: auto;
  background-color: $beige;
  border: solid 1px $grey;
  padding: (2 * $smidge) 16px;

  &--small {
    max-height: 280px;

    @media #{$max-small} {
      max-height: calc(100vh - 400px);
    }
  }

  @media #{$max-small} {
    -webkit-overflow-scrolling: touch;
  }

  .contract-content {
    h5 {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 16px;
      font-weight: 900;
      color: $slate;
    }

    p {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 16px;
      color: $slate;
    }
  }
}
