@import 'assets/styles/_variables.scss';

.trust-icons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 60px;
  min-width: 45px;

  &--inline {
    display: inline-flex;
  }

  &--mini {
    img {
      width: 60%;
    }
  }

  &--no-margin {
    margin-top: 0;
  }

  .trust-col {
    padding-right: 10px;
    padding-left: 10px;

    img {
      display: block;
      margin: auto;
      width: 100%;
    }
  }
}
