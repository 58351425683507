.theme-dark {
  background-color: $navy;

  &.gradient-background {
    background:
      linear-gradient(
        134deg,
        #4e657d,
        $navy
      );
    border: 1px solid $white;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  p,
  small,
  span,
  li {
    color: $white;
  }

  hr {
    border-color: $white;
  }

  .form-group {
    .control-label {
      display: none;
    }

    .form-control {
      background-color: $white;

      &:focus {
        border-color: $space;
      }
    }
  }
}
